import { useForm, yupResolver } from "@mantine/form";
import {
  createStyles,
  Modal,
  Button,
  TextInput,
  Select,
  Box,
  Text,
  Divider
} from "@mantine/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { showNotification } from "@mantine/notifications";
import { Editor } from "@tinymce/tinymce-react";
import {
  fetchProductByIdThunk,
  updateProductThunk,
  generateProductSlugThunk
} from "../../store/slices/product";
import { languageLocaleData } from "../common/parseLanguageNames";
import { BuildingFactory2 } from "tabler-icons-react";
import config from "../../config";

const useStyles = createStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",

    paddingTop: `${theme.spacing.xs}px `,
  },
  root: {
    position: "relative",
    marginTop: 20,
  },
  errorText: {
    color: "#f03e3e",
  },

  input: {
    height: "auto",
    paddingTop: 18,
  },

  label: {
    position: "absolute",
    pointerEvents: "none",
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },

  switch: {
    color: theme.colors.colorBlack,
    "& *": {
      cursor: "pointer",
    },
  },

  insertButton: {
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
  title: {
    lineHeight: 1,
  },
  item: {
    "& + &": {
      borderTop: `1px solid ${theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[2]
        }`,
    },
  },
  modalTitle: {
    fontWeight: "bold",
  },
  selectInput: {
    marginTop: 20,
    zIndex: 2,
  },
  slugButton: {
    position: 'absolute',
    alignSelf: 'center',
    right: '1rem',
    top: 0,
    bottom: 0,
    paddingRight: 0, 
    paddingLeft: '0.6rem',
    margin: '0 !important',
    backgroundColor: theme.colors.colorDarkGray,
    "&:hover": {
      backgroundColor: theme.colors.colorBlack,
    },
  },
}));

const ProductsLocalizationModal = ({
  opened,
  onClose,
  updateData,
  isUpdate,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state?.product?.productData);
  const languageData = useSelector((state) => state?.language?.tableData);
  let params = useParams();
  const [isSlugGenerated, setIsSlugGenerated] = useState(true);

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim(t("productModal.whitespaceValidation"))
      .strict(true)
      .required(t("productModal.categoryValidation"))
      .min(1, t("productModal.nameValidation"))
      .max(255, t("productModal.stringLenghtValidation")),
    locale: Yup.string().min(2, t("productModal.codeValidation")),
    description: Yup.string().test(
      "description",
      t("productModal.descriptionValidation"),
      function (value) {
        return value === "" ? false : true;
      }
    ),
    shortDescription: Yup.string()
      .trim(t("productModal.whitespaceValidation"))
      .strict(true)
      .required(t("productModal.shortDescriptionRequired"))
      .max(1000, t("productModal.shortDescriptionLengthValidation")),
    title: Yup.string()
      .required(t("productModal.titleValidation"))
      .max(
        200,
        t("productModal.titleLength")
      )
      .trim(t("productModal.titleWhitespace"))
      .strict(true),
    metaKeywords: Yup.string()
      .required(t("productModal.keywordsValidation"))
      .max(
        2000,
        t("productModal.keywordsLength")
      )
      .trim(t("productModal.keywordsWhitespace"))
      .strict(true),
    metaDescription: Yup.string()
      .required(t("productModal.metaDescriptionValidation"))
      .max(
        2000,
        t("productModal.metaDescriptionLength")
      )
      .trim(t("productModal.metaDescriptionWhitespace"))
      .strict(true),
    metaRobots: Yup.string()
      .required(t("productModal.robotsValidation"))
      .max(
        200,
        t("productModal.robotsLength")
      )
      .trim(t("productModal.robotsWhitespace"))
      .strict(true),
    slug:Yup.string()
      .required(t("productModal.slugValidations"))
      .max(
        2000,
        t("productModal.slugLength")
      )
      .trim(t("productModal.slugWhitespace"))
      .strict(true),
  });

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      shortDescription: "",
      locale: "",
      title: "",
      metaKeywords: "",
      metaDescription: "",
      metaRobots: "",
      slug: "",
    },
    validate: yupResolver(schema),
  });

  const prepareItemForInsert = (data, itemForUpdate) => {
    let descriptions = [];
    let names = [];
    let shortDescriptions = [];
    let seo = [];
    let slugs = [];

    if (itemForUpdate.descriptions.length) {
      descriptions = itemForUpdate.descriptions.filter(
        (item) => item.locale !== data.locale
      );
    }
    descriptions = [
      ...descriptions,
      { locale: data.locale, description: data.description },
    ];
    if (itemForUpdate.shortDescriptions.length) {
      shortDescriptions = itemForUpdate.shortDescriptions.filter(
        (item) => item.locale !== data.locale
      );
    }
    shortDescriptions = [
      ...shortDescriptions,
      { locale: data.locale, description: data.shortDescription },
    ];
    if (itemForUpdate.names.length) {
      names = itemForUpdate.names.filter((item) => item.locale !== data.locale);
    }
    names = [...names, { locale: data.locale, name: data.name }];

    if (itemForUpdate.seo.length) {
      seo = itemForUpdate.seo.filter(
        (item) => item.locale !== data.locale
      );
    }
    seo = [
      ...seo,
      { 
        locale: data.locale,
        title: data.title,
        metaDescription: data.metaDescription,
        metaKeywords: data.metaKeywords,
        metaRobots: data.metaRobots
      },
    ];

    if (itemForUpdate.slugs.length) {
      slugs = itemForUpdate.slugs.filter(
        (item) => item.locale !== data.locale
      );
    }
    slugs = [
      ...slugs,
      { 
        locale: data.locale,
        slug: data.slug
      },
    ];

    var objUpdate = { ...itemForUpdate };
    objUpdate["names"] = names;
    objUpdate["descriptions"] = descriptions;
    objUpdate["shortDescriptions"] = shortDescriptions;
    //kakvo je ovo smeće pa to je kriminalno
    delete objUpdate.seo;
    objUpdate.seoData = seo;
    objUpdate["slugs"] = slugs;
    return objUpdate;
  };

  const submitForm = async (data) => {
    var updateObject = [];
    var updateData = [];

    if(!isSlugGenerated) {
      data.slug = (await _generateSlug());
    }

    await dispatch(fetchProductByIdThunk(params.productId))
      .unwrap()
      .then((response) => {
        updateData = response;
      })
      .catch((e) => {
        showNotification({
          message: t("productLocalizationModal.saveFailed"),
          color: "red",
        });
      });
    if (isUpdate) {
      updateObject = prepareItemForInsert(data, updateData);
      await dispatch(
        updateProductThunk({ updateData: updateObject, updateObject })
      )
        .unwrap()
        .then(() => {
          showNotification({
            message: t("productLocalizationModal.updateSuccessfull"),
            color: "green",
          });
          form.reset();
          onClose();
        })
        .catch((e) => {
          showNotification({
            message: t("productLocalizationModal.updateFailed"),
            color: "red",
          });
        });
    } else {
      updateObject = prepareItemForInsert(data, updateData);
      await dispatch(updateProductThunk({ updateData, updateObject }))
        .unwrap()
        .then(() => {
          showNotification({
            message: t("productLocalizationModal.updateSuccessfull"),
            color: "green",
          });
          form.reset();
          onClose();
        })
        .catch((e) => {
          showNotification({
            message: t("productLocalizationModal.updateFailed"),
            color: "red",
          });
        });
    }
    await dispatch(fetchProductByIdThunk(updateData.id));
  };

  const _generateSlug = async () => {
    return await dispatch(generateProductSlugThunk({text: form.values.slug, locale: form.values.locale}))
      .unwrap()
      .then((slug) => {
        setIsSlugGenerated(true);
        showNotification({
          message: t("posts.postDetailsForm.slugCreated"),
          color: "green",
        });

        form.setValues({slug: slug})
        return slug;
      })
      .catch((e) => {
        setIsSlugGenerated(false);
        showNotification({
          message: t("posts.postDetailsForm.slugFailedCreate"),
          color: "red",
        });
        return null;
      })
  }

  useEffect(() => {
    form.clearErrors();
    if (isUpdate) {
      form.setValues({
        description: updateData.description,
        shortDescription: updateData.shortDescription,
        name: updateData.name,
        locale: updateData.locale,
        title: updateData.title,
        metaKeywords: updateData.metaKeywords,
        metaDescription: updateData.metaDescription,
        metaRobots: updateData.metaRobots,
        slug: updateData.slug
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <Modal
      size={"xl"}
      closeOnClickOutside={false}
      centered
      opened={opened}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title={t("productLocalizationModal.title")}
      classNames={{ title: classes.modalTitle }}
    >
      <form onSubmit={form.onSubmit(submitForm)}>
        <Select
          style={{ marginBottom: 20, zIndex: 100 }}
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: "#e7f5ff",
                  color: theme.colors.colorDarkGray,
                },
              },
            },
          })}
          className={classes}
          disabled={isUpdate}
          data={
            isUpdate
              ? languageData.map((i) => ({
                value: i?.code,
                label:
                  i?.code +
                  " - " +
                  i?.names
                    .filter((n) => n?.locale === "en")
                    .map(({ name }) => name),
              }))
              : languageLocaleData(languageData, tableData)
          }
          label={t("currencyLocaleModal.locale")}
          placeholder={t("currencyModal.codeInputPlaceholder")}
          classNames={classes}
          searchable
          {...form.getInputProps("locale")}
        />
        <TextInput
          classNames={classes}
          label={t("productLocalizationModal.nameLabel")}
          placeholder={t("productLocalizationModal.namePlaceholder")}
          {...form.getInputProps("name")}
        />

        <div style={{paddingTop: '20px'}}>
          <Text weight={500}>{t("productModal.seoSectionTitle")}</Text>
        </div>
        <Divider />

        <TextInput
          classNames={classes}
          label={t("productModal.seoTitle")}
          placeholder={t("productModal.seoTitleDescription")}
          {...form.getInputProps("title")}
        />
        <TextInput
          classNames={classes}
          label={t("productModal.seoKeywords")}
          placeholder={t("productModal.seoKeywordsPlaceholder")}
          {...form.getInputProps("metaKeywords")}
        />
        <TextInput
          classNames={classes}
          label={t("productModal.seoDescription")}
          placeholder={t("productModal.seoDescriptionPlaceholder")}
          {...form.getInputProps("metaDescription")}
        />
        <TextInput
          classNames={classes}
          label={t("productModal.seoRobots")}
          placeholder={t("productModal.seoRobotsPlaceholder")}
          {...form.getInputProps("metaRobots")}
        />

        <div style={{position: 'relative'}}>
          <TextInput
            classNames={classes}
            label={t("productModal.seoSlug")}
            placeholder={t("productModal.seoSlugPlaceholder")}
            onKeyDown={() => setIsSlugGenerated(false)}
            {...form.getInputProps("slug")}
          />{" "}
          <Button
            leftIcon={<BuildingFactory2 />}
            onClick={() => _generateSlug()}
            className={classes.slugButton}
          >{''}
          </Button>
        </div>

        <div style={{paddingTop: '20px'}}>
          <Text weight={500}>{t("productModal.descriptionTitle")}</Text>
        </div>
        <Divider />

        <TextInput
          classNames={classes}
          label={t("productLocalizationModal.shortDescriptionLabel")}
          placeholder={t("productLocalizationModal.shortDescriptionPlaceholder")}
          {...form.getInputProps("shortDescription")}
        />

        <Box style={{ marginTop: 18 }}>
          <Editor
            apiKey={config.REACT_APP_TINY_MCE_EDITOR_API_KEY}
            value={form?.values?.description}
            onEditorChange={(content) =>
              form.setFieldValue("description", content)
            }
            init={{
              selector: "textarea",
              menubar: false,
              plugins: "link media advlist lists",
              toolbar:
                "bold italic | alignleft aligncenter alignright | numlist bullist outdent indent | link",

              branding: false,
            }}
          />
          {form.errors.description !== null ? (
            <p className={classes.errorText}>{form.errors.description}</p>
          ) : (
            " "
          )}
        </Box>
        <div className={classes.buttonContainer}>
          <Button type="submit" className={classes.insertButton}>
            {t("modalCommon.saveButton")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductsLocalizationModal;
